<template>
  <div>
    <form @submit="submit">
      <button type="submit" class="pay-button" :disabled="disabled">{{ label }}</button>
    </form>
    <div class="c-grid__item">
      <OFormError :message="error"></OFormError>
    </div>
  </div>
</template>

<script>

import OFormError from '../objects/forms/OFormError'
import { mapMutations } from 'vuex'

const getApi = () => import('@/services/api')

export default {
  name: 'CBtcpay',
  components: {
    OFormError
  },
  props: {
    credits: Number,
    cost: Number,
    index: Number,
    email: String,
    label: String
  },
  data: () => ({ error: null, disabled: false }),
  methods: {
    ...mapMutations({
      setGlobalMessage: 'setMessage'
    }),
    async submit (event) {
      event.preventDefault()
      this.disabled = true
      this.error = ''
      const api = await getApi()

      const orderId = await this.getOrderId(api)
      if (!orderId) {
        return
      }

      const paymentUrl = await this.getPaymentUrl(api, orderId)
      if (!paymentUrl) {
        return
      }

      window.location.href = paymentUrl
    },
    async getOrderId (api) {
      const result = await api.createOrder({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        credits: this.credits,
        processor: 'btcpay'
      })
      if (result.error) {
        this.error = result.message
        return false
      }

      return result.orderId
    },
    async getPaymentUrl (api, orderId) {
      const result = await api.createBtcpayOrder({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        orderId: orderId
      })
      if (result.error) {
        this.error = result.message
        return false
      }

      return result.paymentUrl
    }
  }
}
</script>

<style lang="scss">
  .pay-button {
    background: $primary;
    color: #fff;
    font-size: 1.15em;
    border-radius: 4px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    max-width: 750px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: none;
    vertical-align: top;
    cursor: pointer;
    overflow: hidden;
  }
</style>
